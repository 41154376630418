<template>
  <div class="about-us">
    <div class="header d-flex flex-row">
      <div class="left d-flex flex-column justify-center">
        <h3>Let’s get acquainted!</h3>
        <p>Explore who we are</p>

        <div class="scroll">
          <img
            src="@/assets/images/Delivery/scroll.png"
            alt="scroll"
            class="scroll-arrow"
          />
        </div>
      </div>
      <div class="right d-flex flex-row align-center">
        <img src="@/assets/images/AboutUs/main-flower.png" alt="main-flower" />
      </div>
    </div>

    <div class="we-are">
      <h3>Hi! We are Bloemenmaker!</h3>
      <p>
        The best floral arrangements are as unique and special as the people you
        want to send them to. Our artisanal florists ensure that every time you
        order flowers online from Bloemenmaker, you and your loved ones receive
        a loving crafted celebration of natural beauty.
      </p>
    </div>

    <Slider />

    <div class="speed-delivery d-flex flex-row align-center space-around">
      <img
        src="@/assets/icons/Others/delivery.png"
        alt="delivery"
        class="our-app-icon"
      />
      <div class="left">
        <img
          src="@/assets/images/Delivery/laptop-flowers.png"
          alt="laptop-flowers.png"
          class="flower"
        />
      </div>
      <div class="right">
        <h5>Delivery</h5>

        <h3>Speed delivery</h3>
        <p>
          Each order we pack in our secure box. You can be sure that youe
          bouquet will look terific. It is completly for free!
        </p>
      </div>
    </div>

    <div class="flower-maker">
      <div class="flower-maker-container">
        <h5 class="slogan">Orders</h5>
        <h3 class="title">Wij zijn de Bloemenmaker. Vol verrassingen</h3>

        <div class="wrapper d-flex flex-row justify-between">
          <div class="flower-maker__left d-flex flex-column align-start">
            <div class="flower-maker__left-item d-flex flex-row align-start">
              <img
                src="@/assets/images/AboutUs/flower-maker1.png"
                alt="flower-maker1.png"
              />
              <div class="content">
                <h5>Bloemenmakerfamilie voordelen*</h5>
                <ul>
                  <li>Betaal geen bezorgkosten</li>
                  <li>Bezorging ook op zondagen</li>
                </ul>
              </div>
            </div>
            <div class="flower-maker__left-item d-flex flex-row align-start">
              <img
                src="@/assets/images/AboutUs/flower-maker2.png"
                alt="flower-maker2.png"
              />
              <div class="content">
                <h5>
                  Alle boeketten worden gemaakt op één locatie door onze eigen
                  bloemisten
                </h5>
              </div>
            </div>
            <div class="flower-maker__left-item d-flex flex-row align-start">
              <img
                src="@/assets/images/AboutUs/flower-maker3.png"
                alt="flower-maker3.png"
              />
              <div class="content">
                <h5>Wij maken écht mooie boeketten</h5>
              </div>
            </div>
          </div>
          <div class="flower-maker__right d-flex flex-column align-start">
            <div class="flower-maker__right-item d-flex flex-row align-start">
              <img
                src="@/assets/images/AboutUs/flower-maker4.png"
                alt="flower-maker5.png"
              />
              <div class="content">
                <h5>Wij bezorgen door heel Nederland*</h5>
              </div>
            </div>
            <div class="flower-maker__right-item d-flex flex-row align-start">
              <img
                src="@/assets/images/AboutUs/flower-maker5.png"
                alt="flower-maker5.png"
              />
              <div class="content">
                <h5>Handige app met extra voordelen</h5>
                <ul>
                  <li>Spaarkaart; spaar voor leuke kortingen</li>
                  <li>Voeg een gratis videoboodschap toe aan jouw boeket</li>
                </ul>
              </div>
            </div>
            <div class="flower-maker__right-item d-flex flex-row align-start">
              <div class="content">
                <h6>
                  * Je wordt lid van Bloemenmakerfamilie door een account aan te
                  maken via onze website of app * Met uitzondering van de
                  Waddeneilanden
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "@/components/Slider";

export default {
  name: "AboutUs",

  components: {
    Slider,
  },
};
</script>

